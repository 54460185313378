<template>
  <div>
    <div class="container">
      <div class="wrapper">
        <Header title="保險服務商聯絡資訊" backto="/" />
        <Menu :menu="menu" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Menu from '@/components/Menu.vue';

export default {
  data() {
    return {
      menu: [
        {
          name: '富邦產險',
          target: 'tel:0800-009-888',
          notes: '0800-009-888',
          externalLink: true,
        },
        {
          name: '新安東京',
          target: 'tel:0800-050-119',
          notes: '0800-050-119',
          externalLink: true,
        },
        {
          name: '新光產險',
          target: 'tel:0800-789-999',
          notes: '0800-789-999',
          externalLink: true,
        },
        {
          name: '國泰產險',
          target: 'tel:0800-212-880',
          notes: '0800-212-880',
          externalLink: true,
        },
        {
          name: '中國信託',
          target: 'tel:0800-075-777',
          notes: '0800-075-777',
          externalLink: true,
        },
        {
          name: '和泰產險',
          target: 'tel:0800-077-568',
          notes: '0800-077-568',
          externalLink: true,
        },
        {
          name: '明台產險',
          target: 'tel:0800-099-080',
          notes: '0800-099-080',
          externalLink: true,
        },
        {
          name: '台灣產險',
          target: 'tel:0809-068-888',
          notes: '0809-068-888',
          externalLink: true,
        },
        {
          name: '兆豐產險',
          target: 'tel:0800-053-588',
          notes: '0800-053-588',
          externalLink: true,
        },
        {
          name: '泰安產險',
          target: 'tel:0800-012-080',
          notes: '0800-012-080',
          externalLink: true,
        },
        {
          name: '第一產險',
          target: 'tel:0800-288-068',
          notes: '0800-288-068',
          externalLink: true,
        },
        {
          name: '華南產險',
          target: 'tel:0800-010-850',
          notes: '0800-010-850',
          externalLink: true,
        },
      ],
    };
  },
  components: {
    Header,
    Menu,
  },
};
</script>

<style></style>
